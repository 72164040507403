import {ApolloClient, HttpLink, InMemoryCache} from 'apollo-boost';
import 'isomorphic-fetch';

export const shopify = new ApolloClient({
  link: new HttpLink({
    uri: `https://${process.env.SHOPIFY.DOMAIN}/api/${process.env.SHOPIFY.VERSION}/graphql.json`,
    headers: {
      "X-Shopify-Storefront-Access-Token": process.env.SHOPIFY.ACCESS_TOKEN
    },
  }),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  }
});
