import findIndex from "lodash/findIndex";

export function getAttributeValue(node, key) {
  const attributes = node.customAttributes || [];
  const attributeIndex = findIndex(attributes, {key});

  if (attributeIndex >= 0) {
    return attributes[attributeIndex].value;
  }

  return null;
}
